<template>
  <Details
    title="Quext user"
    resource="quextUsers"
    base-path="quextUsers.index"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel entity-title="quext user" new-entity></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <QuextUserForm
        :initialValues="record"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import QuextUserForm from '@/views/auth/quextUsers/QuextUserForm';
  import Details from '@/components/auth/details/Details';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";

  export default {
    name: 'QuextUsersCreate',
    components: {
      DetailsSidePanel,
      QuextUserForm,
      Details
    },
    mixins: [ModalNavigation],
    data() {
      return {
        record: {
          mfaRequired: true,
        },
      };
    },
  };
</script>

<style scoped>

</style>
